export default {
  methods: {
    async goToRoom(roomId = null) {
      if (roomId === null) {
        return this.$store.commit('layouts/DISPLAY_TOAST', {
          message: 'Room not found',
        })
      }

      if (roomId !== this.$route.query.room) {
        await this.$store.commit('conversation/SET_INITIAL_CONDITION')
        await this.$store.commit('conversation/SET_CONTACT_DETAIL_DATA', null)
        await this.$store.commit('conversation/UPDATE_ACTIVE_ROOM_DATA', [])
        await this.$router.push({ name: 'inbox', query: { room: roomId } })
        await this.$store.dispatch('conversation/getParticipantLists', {
          id: roomId,
        })
      } else {
        this.$store.commit('layouts/DISPLAY_TOAST', {
          message: `You are already in this room`,
        })
      }
    },
    hoverActiveRoom(session, status) {
      let output = 'Go to latest chat room'
      if (
        (session !== 'expired' && status !== 'resolved') ||
        status !== 'resolved'
      )
        output = '1 active room'
      return output
    },
    getChannelIcon(channel) {
      if (channel) {
        switch (channel.toLowerCase()) {
          case 'whatsapp':
            return 'lab la-whatsapp'
          case 'wa':
            return 'lab la-whatsapp'
          case 'wa_cloud':
            return 'lab la-whatsapp'
          case 'qontak':
            return 'las la-comments'
          case 'email':
            return 'las la-envelope'
          case 'qiscus':
            return 'las la-comments'
          case 'telegram':
            return 'lab la-telegram'
          case 'fb':
            return 'lab la-facebook-messenger'
          case 'livechat_dot_com':
            return 'las la-comment-alt'
          case 'ig':
            return 'lab la-instagram'
          case 'ig_comment':
            return 'lab la-instagram'
          case 'twitter':
            return 'lab la-twitter'
          case 'line':
            return 'lab la-line'
          case 'website':
            return 'las la-laptop'
          case 'web_chat':
            return 'las la-comment'
          case 'app_chat':
            return 'las la-comment'
          default:
            return 'las la-comment'
        }
      } else {
        return 'las la-exclamation-circle'
      }
    },
    formatSpaceToUnderscore(value) {
      return value.replace(/ /g, '_')
    },
    formatUnderscoreToSpace(value) {
      return value.replace(/_/g, ' ')
    },
    singleLineTextRules(isRequired, name, validationObj) {
      const validation = validationObj[0]
      const validationObject = {}

      if (validation === 'alpha_num' || validation === 'alphanum') {
        validationObject.alpha_num = true
        validationObject.min = 2
        validationObject.max = 100
      } else if (validation === 'email') {
        validationObject.email = true
        validationObject.min = 2
        validationObject.max = 100
      } else if (validation === 'number') {
        validationObject.regex = /(^-|^\+|\d)/
        validationObject.min = 2
        validationObject.max = 100
      } else if (validation === 'username') {
        validationObject.regex = /^[-\w\d.]+$/i
        validationObject.min = 2
        validationObject.max = 100
      }

      if (isRequired) validationObject.required = true
      return validationObject
    },
    numberRules(isRequired, name, validationObj) {
      const validation = validationObj[0]
      let validate = {
        regex: /(^-|^\+|\d)/,
        max: 15,
      }
      if (isRequired) validate.required = true
      if (!validation) validate = null
      return validate
    },
    textareaRules(isRequired, name, validationObj) {
      const validation = validationObj[0]
      const validationObject = {}

      if (validation === 'alpha_num' || validation === 'alphanum') {
        validationObject.alpha_num = true
        validationObject.min = 2
        validationObject.max = 300
      } else if (validation === 'email') {
        validationObject.email = true
        validationObject.min = 2
        validationObject.max = 300
      } else if (validation === 'number') {
        validationObject.regex = /(^-|^\+|\d)/
        validationObject.min = 2
        validationObject.max = 300
      } else if (validation === 'username') {
        validationObject.regex = /^[-\w\d.]+$/i
        validationObject.min = 2
        validationObject.max = 300
      }

      if (isRequired) validationObject.required = true
      return validationObject
    },
    dateRules(isRequired, name, validationObj) {
      const validation = validationObj[0]
      let validate = null
      if (isRequired) validate = 'required'
      if (!validation) validate = null
      return validate
    },
    fileUploadRulesOld(isRequired, validation) {
      let validate = ''
      if (isRequired) validate = 'required'
      if (validation[0] === 'image') {
        if (!validate || validate === '') {
          validate = `${validation}`
        } else {
          validate = `${validate}|${validation}`
        }
      }
      return validate
    },
    fileUploadRules(isRequired, name, validationObj) {
      const validation = validationObj[0]
      const validationObject = {}
      if (isRequired) validationObject.required = true
      if (validation)
        validationObject.ext = this.acceptedFile(validationObj).replaceAll(
          '.',
          ''
        )
      return validationObject
    },
    acceptedFile(type) {
      if (type[0] === 'document' || type[0] === 'doc') {
        return '.doc, .docx, .pdf, .ppt, .pptx, .xls, .xlsx, .xml, .odp, .odt, .ods, .rar, .zip, .tar.gz, .yaml, .7z, .txt,'
      } else if (type[0] === 'image') {
        return '.jpeg, .jpg, .png, .png, .gif, .webp, .svg'
      } else {
        return null
      }
    },
  },
}
