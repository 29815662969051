/* eslint-disable no-console */
import axios from 'axios'
import * as Storage from '@/utils/storage'

const errorPrefix = '[ERROR] '
const errorHandler = {
  somethingWrong() {
    console.error(errorPrefix, 'Something wrong')
  },

  badRequest() {
    console.error(errorPrefix, 'Bad Request')
  },

  unauthenticated(error) {
    console.error(errorPrefix, 'Unauthenticated')
    if (error.message === 'Invalid token') {
      window.$nuxt.$auth.logout()
      Storage.clearStorage()
      window.location.reload()
    }
  },

  notAuthorize() {
    console.error(errorPrefix, 'Not Authorize')
  },

  notFound() {
    console.error(errorPrefix, 'Not Found')
  },

  unprocessableEntity() {
    console.error(errorPrefix, 'Unprocessable Entity')
  },
}

export default (error) => {
  if (axios.isCancel(error)) {
    return
  }

  if (error?.isAxiosError && error?.message === 'Network Error') {
    window.$nuxt.$rollbar.error(error.message)
    errorHandler.somethingWrong()
    return
  }

  const response = error.response

  // add getter message to error object
  const { error: errorObject } = response.data
  error.message =
    errorObject?.messages && errorObject.messages.length > 0
      ? errorObject.messages[0]
      : response.statusText

  if (
    typeof response === 'undefined' ||
    typeof response.status === 'undefined'
  ) {
    errorHandler.somethingWrong()
    return Promise.reject(error)
  }

  switch (response.status) {
    case 400:
      errorHandler.badRequest()
      break
    case 401:
      errorHandler.unauthenticated(error)
      break
    case 403:
      errorHandler.notAuthorize()
      break
    case 404:
      errorHandler.notFound()
      break
    case 422:
      errorHandler.unprocessableEntity()
      break
    default:
      window.$nuxt.$rollbar.error(error.message)
      errorHandler.somethingWrong()
      break
  }

  return Promise.reject(error)
}
