import axios from 'axios'
import handler from './handler'

import { getStorage } from '@/utils/storage'

import { auth } from '@/common/constants'

function createResource() {
  const instance = axios.create({
    baseURL: process.env.CHATBOT_SERVICE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  instance.interceptors.request.use(
    (config) => {
      const token = getStorage(auth.COOKIE_KEY)

      if (token) {
        config.headers.Authorization = `${token}`
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response)
    },
    (error) => {
      return handler(error)
    }
  )

  return instance
}

export default createResource()
